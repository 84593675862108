import OnboardingLayout from '@c/layouts/OnboardingLayout';
import LoginOrSignupPage from '@c/LoginOrSignupPage';

const Login = () => {
  return <LoginOrSignupPage variant="login" />;
};

Login.getLayout = function getLayout(page: React.ReactElement) {
  return <OnboardingLayout>{page}</OnboardingLayout>;
};

export default Login;
